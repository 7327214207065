//@charset "UTF-8";
//
////@import 'vendor/normalize';
//
//@font-face {
//  font-family: 'Avenir Next Condensed Bold Italic';
//  src: url('../../../server/assets/fonts/AvenirNextCondensed-BoldItalic.ttf') format('truetype');
//  font-weight: bold;
//  font-style: italic;
//}
//
//@font-face {
//  font-family: 'Avenir Next Condensed Italic';
//  src: url('../../../server/assets/fonts/AvenirNextCondensed-Italic.ttf') format('truetype');
//  font-weight: normal;
//  font-style: italic;
//}
//
//@font-face {
//  font-family: 'Avenir Next Condensed Bold';
//  src: url('../../../server/assets/fonts/AvenirNextCondensed-Bold.ttf') format('truetype');
//  font-weight: bold;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'Avenir Next Condensed Regular';
//  src: url('../../../server/assets/fonts/AvenirNextCondensed-Regular.ttf') format('truetype');
//  font-weight: normal;
//  font-style: normal;
//}
//
//a {
//  color: #ff4081;
//  text-decoration: none;
//
//  &:hover {
//    text-decoration: underline;
//  }
//}
//
//html {
//  // font-family: 'Roboto', sans-serif;
//  //-webkit-font-smoothing: antialiased;
//  font-family: 'Avenir Next Condensed Regular', sans-serif;
//}
//
//body {
//  background: white !important;
//}
//
//.tabsBtn {
//  margin-right: 3px !important;
//  transform: skew(-21deg);
//  z-index: 1;
//  font-weight: bold !important;
//}
//
//.tabsBtn > span  > span  > span > span {
//  transform: skew(21deg);
//  display: inline-block;
//  font-size: 1rem !important;
//}
//
//.tabsBtn.active {
//  background-color: black;
//  color: white;
//}
//
//.tabsBtn.First, .tabsBtn.Last {
//  transform: skew(0deg);
//  border: none !important;
//  border-left: solid #666 1px;
//  &:after {
//    content: '';
//    position: absolute;
//    width: 100%;
//    height: 100%;
//    z-index: -1;
//    transform: skew(-21deg);
//    border: solid #666 1px;
//  }
//}
//
//.tabsBtn.First > span  > span  > span > span ,
//.tabsBtn.Last > span  > span  > span > span {
//  transform: skew(0deg);
//}
//
//.tabsBtn.First {
//  margin-right: -6px !important;
//  border-left: solid #666 1px !important;
//  &:after {
//    transform-origin: top right;
//  }
//}
//
//.tabsBtn.Last {
//  margin-left: -9px !important;
//  border-right: solid #666 1px !important;
//  &:after {
//    transform-origin: bottom right;
//  }
//}
//
//.tabsBtn.default {
//  background-color: #eee;
//  color: black;
//  border: solid #666 1px;
//}
//
//.tabsBtn.First.default, .tabsBtn.Last.default {
//  color: black;
//  background-color: transparent !important;
//  &:after {
//    background-color: #eee;
//  }
//}
//
//.tabsBtn.First.active, .tabsBtn.Last.active {
//  color: white;
//  background-color: transparent !important;
//  &:after {
//    background-color: black;
//  }
//}

html {
  background-color: #212121;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  //font-family: 'Avenir Next Condensed Regular', sans-serif;
  //cursor: none;
}

svg {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: visible;
  cursor: pointer;
}

g {
  transform-origin: 50% 50%;
  animation: rotate 10s linear infinite;
}

ellipse {
  fill: none;
  stroke: #61dafb;
  stroke-width: 16px;
  stroke-linecap: round;
  stroke-dasharray: 212.3285, 30;
  stroke-dashoffset: 721;
  animation: animate 2s linear infinite;
}

circle {
  fill: #61dafb;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes animate {
  100% {
    stroke-dashoffset: 0;
  }
}

h1 {
  color: #fff;
  text-align: center;
}